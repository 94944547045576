"use client";

import { useEffect } from "react";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { ConversionEvent } from "./klikaya.types";

export function useKilkayaConversion(conversionEvent: ConversionEvent | null) {
  const consent = useCookiebotConsent();

  useEffect(() => {
    if (
      consent.submitted &&
      conversionEvent !== null &&
      typeof kilkaya !== "undefined"
    ) {
      // eslint-disable-next-line no-undef -- kilkaya is defined in the global scope
      kilkaya.pageview({
        cntTag: conversionEvent,
        conversion: 1,
        nopv: 1,
      });
    }
  }, [consent, conversionEvent]);
}
