"use client";

import { NewsletterSignUpHeading } from "@/components/NewsletterSignUp/components/NewsletterSignUpHeading/NewsletterSignUpHeading.component";
import { useSite } from "@/contexts/site/site.context";
import type { ConversionEvent } from "@/hooks/tracking/kilkaya/klikaya.types";
import { useKilkayaConversion } from "@/hooks/tracking/kilkaya/use-kilkaya-conversion.hook";
type NewsletterSuccessBoxProps = {
  readonly conversionEvent: ConversionEvent;
};
export function NewsletterSuccessBox({
  conversionEvent
}: NewsletterSuccessBoxProps) {
  const {
    locale
  } = useSite();
  useKilkayaConversion(conversionEvent);
  return <NewsletterSignUpHeading data-sentry-element="NewsletterSignUpHeading" data-sentry-component="NewsletterSuccessBox" data-sentry-source-file="NewsletterSuccessBox.component.tsx">
      {{
      da: "Du er nu tilmeldt vores nyhedsbrev",
      de: "Sie sind jetzt für unseren Newsletter angemeldet.",
      en: "You are now subscribed to our newsletter.",
      no: "Du er nå påmeldt vårt nyhetsbrev",
      sv: "Du är nu prenumerant på vårt nyhetsbrev."
    }[locale]}
    </NewsletterSignUpHeading>;
}