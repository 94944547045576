"use client";

import { SubmitButton } from "@/components/Button/components/SubmitButton/SubmitButton.component";
import { useSite } from "@/contexts/site/site.context";
import { isPending, type Fetchable } from "@/services/utilities/fetchable";
type NewsletterSignUpSubscribeButtonProps = {
  readonly status: Fetchable;
  readonly isDisabled: boolean;
};
export function NewsletterSignUpSubscribeButton({
  isDisabled,
  status
}: NewsletterSignUpSubscribeButtonProps) {
  const {
    locale
  } = useSite();
  return <div className="flex justify-center" data-sentry-component="NewsletterSignUpSubscribeButton" data-sentry-source-file="NewsletterSignUpSubscribeButton.component.tsx">
      <SubmitButton className="w-full" disabled={isDisabled} isPending={isPending(status)} data-sentry-element="SubmitButton" data-sentry-source-file="NewsletterSignUpSubscribeButton.component.tsx">
        {{
        da: "Tilmeld",
        de: "Anmelden",
        en: "Subscribe",
        no: "Registrer",
        sv: "Prenumerera"
      }[locale]}
      </SubmitButton>
    </div>;
}