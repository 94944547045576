"use client";

import { clsx } from "clsx";
import NextImage from "next/image";
import type { FormEvent } from "react";
import { useCallback, useRef, useState } from "react";
import { Link } from "@/components/Link/Link.component";
import { useSite } from "@/contexts/site/site.context";
import type { ConversionEvent } from "@/hooks/tracking/kilkaya/klikaya.types";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending, pending } from "@/services/utilities/fetchable";
import { inlineJsx } from "@/ts/locale/inline-jsx";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { RegularButton } from "../Button/components/RegularButton/RegularButton.component";
import { CheckboxWithLabel } from "../Checkbox/CheckboxWithLabel.component";
import { Email } from "../Input/Email/Email.component";
import { SwitchFetchable } from "../SwitchFetchable/SwitchFetchable.component";
import { Translation } from "../Translation/Translation.component";
import type { NewsletterModel } from "./_models/newsletter.model";
import { NewsletterSignUpHeading } from "./components/NewsletterSignUpHeading/NewsletterSignUpHeading.component";
import { NewsletterSignUpSubscribeButton } from "./components/NewsletterSignUpSubscribeButton/NewsletterSignUpSubscribeButton.component";
import { NewsletterSuccessBox } from "./components/NewsletterSuccessBox/NewsletterSuccessBox.component";
import newsletterSignUpBackground from "./newsletter-sign-up-background.png";
type NewsletterSignUpProps = {
  readonly className?: string;
  readonly backgroundTrait: "gradient" | "solid";
  readonly conversionEventTags: ConversionEvent;
  readonly initialSubmissionState?: Fetchable;
  readonly model: NewsletterModel;
  readonly onSubmit: (email: string) => Promise<Fetchable>;
};
export function NewsletterSignUp({
  backgroundTrait = "solid",
  conversionEventTags,
  initialSubmissionState = initial(),
  model,
  onSubmit,
  className
}: NewsletterSignUpProps) {
  const [submission, setSubmission] = useState<Fetchable>(initialSubmissionState);
  const {
    description,
    termLink,
    showCommercialConsent
  } = model;
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    locale
  } = useSite();
  function checkFormValidity() {
    setIsFormValid(Boolean(formRef.current?.checkValidity()));
  }
  const submit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Stops the browser from refreshing the page upon submitting the form.

    if (!isFormValid) {
      return;
    }

    // FormData doesn’t include disabled fieldsets, so we have to get the data BEFORE setting pending state,
    // because this disables the email input: https://www.silvestar.codes/articles/form-data-doesn-t-work-well-with-disabled-fieldset-elements/
    const formData = new FormData(formRef.current ?? undefined);
    const emailValue = (formData.get("email") as string);

    // We have to set pending state after getting form data. See why in the comment above.
    setSubmission(pending());
    setSubmission(await onSubmit(emailValue));
  }, [isFormValid, onSubmit]);
  const termsAndConditionsLink = <Link className="text-primary hover:underline" href={termLink} position="newsletter-sign-up-terms" rel="noreferrer" openInNewTab>
      <Translation da="vilkårene" de="die Nutzungsbedingungen" en="the terms and conditions" no="vilkårene" sv="villkoren" />
    </Link>;
  return <article className={clsx("relative z-0 min-h-[300px] overflow-hidden bg-whisper", className)} data-sentry-component="NewsletterSignUp" data-sentry-source-file="NewsletterSignUp.component.tsx">
      <div className="relative z-20 grid grid-cols-6 gap-x-2 px-2 py-3 lg:gap-x-3">
        <div className="col-span-5 row-start-1 lg:col-span-3">
          <SwitchFetchable fetchable={submission} renderDefault={() => <>
                <NewsletterSignUpHeading>
                  {{
              da: "Tilmeld dig vores nyhedsbrev",
              de: "Zum Newsletter anmelden",
              en: "Sign up for our newsletter",
              no: "Meld deg på vårt nyhetsbrev",
              sv: "Prenumerera på vårt nyhetsbrev"
            }[locale]}
                </NewsletterSignUpHeading>

                <p className="leading-snug text-charcoal">{description}</p>

                <form className="col-span-full row-start-2 mt-4 flex flex-col gap-y-2 lg:col-span-3" ref={formRef} onSubmit={submit}>
                  <Email disabled={isPending(submission)} name="email" required onChange={checkFormValidity} />

                  {showCommercialConsent ? <CheckboxWithLabel id="newsletterAcceptTerms" isDisabled={isPending(submission)} isRequired={showCommercialConsent} onChange={checkFormValidity}>
                      {{
                da: inlineJsx`Jeg accepterer ${termsAndConditionsLink} for nyhedsbreve`,
                de: inlineJsx`Ich akzeptiere die ${termsAndConditionsLink}`,
                en: inlineJsx`I accept the ${termsAndConditionsLink}`,
                no: inlineJsx`Jeg aksepterer ${termsAndConditionsLink}`,
                sv: inlineJsx`Jag accepterar ${termsAndConditionsLink}`
              }[locale]}
                    </CheckboxWithLabel> : null}

                  <NewsletterSignUpSubscribeButton isDisabled={!isFormValid} status={submission} />
                </form>

                {!showCommercialConsent ? <Link className="block py-1 text-sm text-nobel hover:underline" href={termLink} openInNewTab>
                    {{
              da: "Nyhedsbrevsvilkår",
              de: "Newsletter-Bedingungen",
              en: "Newsletter terms",
              no: "Vilkår for nyhetsbrev",
              sv: "Nyhetsbrevsvillkor"
            }[locale]}
                  </Link> : null}
              </>} renderFailed={() => <>
                <p className="leading-snug text-charcoal">
                  {{
              da: "Det lykkedes ikke at tilmelde dig nyhedsbrevet.",
              de: "Bei der Anmeldung zu unserem Newsletter ist ein Fehler aufgetreten.",
              en: "There was an error subscribing to the newsletter.",
              no: "Noe gikk galt under påmeldingen til nyhetsbrevet.",
              sv: "Det gick inte att prenumerera på nyhetsbrevet."
            }[locale]}
                </p>
                <RegularButton className="mt-2 w-full" onClick={reset}>
                  {{
              da: "Prøv igen",
              de: "Versuchen Sie es bitte erneut",
              en: "Try again",
              no: "Prøv igjen",
              sv: "Försök igen"
            }[locale]}
                </RegularButton>
              </>} renderSucceeded={() => <NewsletterSuccessBox conversionEvent={conversionEventTags} />} data-sentry-element="SwitchFetchable" data-sentry-source-file="NewsletterSignUp.component.tsx" />
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 z-10 hidden w-1/2 lg:block">
        <svg aria-hidden="true" className="ml-[-1px] h-full w-full fill-whisper" preserveAspectRatio="none" viewBox="0 0 5 1" data-sentry-element="svg" data-sentry-source-file="NewsletterSignUp.component.tsx">
          <polygon points="0,0 1,0 0.02,1 0,1" data-sentry-element="polygon" data-sentry-source-file="NewsletterSignUp.component.tsx" />
        </svg>
      </div>
      <div className={clsx("absolute inset-y-0 right-0 z-0 w-2/12 lg:w-1/2", backgroundTrait === "solid" ? "bg-primary brightness-90" : "bg-gradient-to-l from-primary to-secondary")}>
        <NextImage alt="" className="select-none object-cover mix-blend-multiply" draggable={false} sizes={`(max-width: ${tailwindScreenSize.md}) 90vw, 490px`} src={newsletterSignUpBackground} fill data-sentry-element="NextImage" data-sentry-source-file="NewsletterSignUp.component.tsx" />
      </div>
    </article>;
  function reset() {
    setSubmission(initial());
  }
}