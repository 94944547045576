"use client";

import { useReCaptcha } from "next-recaptcha-v3";
import { useServerConfiguration } from "@/contexts/server-configuration/server-configuration.context";
import { useSite } from "@/contexts/site/site.context";
import type { ConversionEvent } from "@/hooks/tracking/kilkaya/klikaya.types";
import { createNewsletterApiService } from "@/services/api/newsletter/newsletter-api.service";
import type { Fetchable } from "@/services/utilities/fetchable";
import { failed } from "@/services/utilities/fetchable";
import type { NewsletterModel } from "./_models/newsletter.model";
import { NewsletterSignUp } from "./NewsletterSignUp.component";
export type NewsletterSignupRecaptchaHandlerProps = {
  readonly className?: string;
  readonly conversionEventTags: ConversionEvent;
  readonly isTabloid?: boolean;
  readonly model: NewsletterModel;
};
export function NewsletterSignupRecaptchaHandler({
  className,
  conversionEventTags,
  isTabloid = false,
  model
}: NewsletterSignupRecaptchaHandlerProps) {
  const {
    id: siteId
  } = useSite();
  const {
    newsletterApiDomain
  } = useServerConfiguration();
  const {
    executeRecaptcha
  } = useReCaptcha();
  const {
    domain
  } = useSite();
  const {
    submitNewsletterSubscription
  } = createNewsletterApiService({
    newsletterApiDomain,
    siteId
  });
  async function submitHandler(email: string): Promise<Fetchable> {
    const recaptchaToken = await executeRecaptcha(`${domain.replace(".", "_")}_newsletter_signup`);
    if (!recaptchaToken) {
      return failed("No reCAPTCHA token received.");
    }
    const state = await submitNewsletterSubscription(email, recaptchaToken);
    return state;
  }
  return <NewsletterSignUp backgroundTrait={isTabloid ? "gradient" : "solid"} className={className} conversionEventTags={conversionEventTags} model={model} onSubmit={submitHandler} data-sentry-element="NewsletterSignUp" data-sentry-component="NewsletterSignupRecaptchaHandler" data-sentry-source-file="NewsletterSignupRecaptchaHandler.component.tsx" />;
}