import { clsx } from "clsx";
import type { ReactNode } from "react";
type NewsletterSignUpHeadingProps = {
  readonly className?: string;
  readonly children: ReactNode;
};
export function NewsletterSignUpHeading({
  className,
  children
}: NewsletterSignUpHeadingProps) {
  return <h1 className={clsx(className, "mb-1 text-lg font-theme-bold tracking-tight lg:text-xl")} data-sentry-component="NewsletterSignUpHeading" data-sentry-source-file="NewsletterSignUpHeading.component.tsx">
      {children}
    </h1>;
}