import { clsx } from "clsx";
import type { MouseEventHandler, ReactNode } from "react";
type RegularButtonProps = {
  readonly className?: string;
  readonly disabled?: boolean; // eslint-disable-line react/boolean-prop-naming -- The name should match the official one in HTML.
  readonly onClick?: MouseEventHandler<HTMLButtonElement>;
  readonly children: ReactNode;
};
export function RegularButton({
  className,
  disabled,
  onClick,
  children
}: RegularButtonProps) {
  return <button className={clsx(className, "common-button", "hover-button")} disabled={disabled} type="button" onClick={onClick} data-sentry-component="RegularButton" data-sentry-source-file="RegularButton.component.tsx">
      {children}
    </button>;
}