import type { SiteId } from "@/contexts/site/models/site.types";
import { clientFetcher } from "@/services/utilities/fetch/client-fetcher";
import { succeeded, failed } from "@/services/utilities/fetchable";
import type { Fetchable } from "@/services/utilities/fetchable";

type NewsletterApiService = {
  readonly submitNewsletterSubscription: (
    email: string,
    recaptchaToken: string,
  ) => Promise<Fetchable>;
};

type CreateNewsletterApiServiceProps = {
  readonly siteId: SiteId;
  readonly newsletterApiDomain: string;
};

export function createNewsletterApiService({
  siteId,
  newsletterApiDomain,
}: CreateNewsletterApiServiceProps): NewsletterApiService {
  return {
    submitNewsletterSubscription: async (email, recaptchaToken) => {
      try {
        const url = new URLSearchParams();

        url.set("siteTag", siteId);
        url.set("recaptchaToken", recaptchaToken);
        url.set("recaptchaVersion", "3");
        url.set("email", email);

        const newsletterPostUrl = `${newsletterApiDomain}/api/v2/subscribe?${url.toString()}`;
        const response = await clientFetcher(newsletterPostUrl, {
          method: "POST",
        });

        return response.ok
          ? succeeded()
          : failed(`Unsuccessful request: ${response.statusText}`);
      } catch (error) {
        return error instanceof Error
          ? failed(`Network error: ${error.message}`)
          : failed("Unspecified error");
      }
    },
  };
}
