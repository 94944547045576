import clsx from "clsx";
import type { ChangeEvent } from "react";
export type CheckboxProps = {
  readonly className?: string;
  readonly isChecked?: boolean;
  readonly isDisabled?: boolean;
  readonly isRequired?: boolean;
  readonly isReadOnly?: boolean;
  readonly id?: string;
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};
export function Checkbox({
  className,
  isChecked,
  isDisabled,
  isReadOnly,
  isRequired,
  id,
  onChange
}: CheckboxProps) {
  return <input checked={isChecked} className={clsx(className, "h-3 w-3 rounded-sm border-silver text-primary transition-all focus:ring-1 focus:ring-offset-0 enabled:cursor-pointer enabled:hover:border-dim disabled:bg-whisper disabled:checked:bg-silver")} data-testid={id} disabled={isDisabled} id={id} readOnly={isReadOnly} required={isRequired} type="checkbox" onChange={onChange} onInvalid={event => event.preventDefault()} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.component.tsx" />;
}